import * as S from 'apps/cliniko/style'
import Link, { styles as linkStyles } from 'components/link'
import Loader from 'components/loader'
import * as _ from 'modules/util'
import { isValidElement } from 'react'
import { colorStyles } from './color-styles'
import Count from './count'
import Swatch from './swatch'
import ToggleIcon from './toggle-icon'

const Button = ({
  count,
  children,
  color,
  compact,
  disabled: disabledIn,
  icon = {},
  isLoading = false,
  label = children,
  loader = <Loader inherit />,
  link,
  linkIcon,
  maxCount,
  ref,
  swatch,
  toggle,
  truncate,
  ...props
}) => {
  _.throwWhen(
    color && !Object.keys(colorStyles).includes(color),
    `An invalid color "${color}" was given to <Button />. Use one of ${Object.keys(
      colorStyles
    ).join(', ')}`
  )
  _.throwWhen(
    link && props.href,
    'Use a Link component instead of "link" and "href" props on a Button'
  )

  const disabled = disabledIn || isLoading

  const { left: iconLeft, right: iconRight } = isValidElement(icon) ? { left: icon } : icon
  const style = styles({ color, compact, disabled, isLoading, link, truncate })

  const contentContainer = (
    <span
      css={contentContainerStyles({
        hasLabel: !!label,
        iconLeft: !!iconLeft,
        iconRight: !!iconRight,
        isLink: Boolean(props.href || props.search),
        link,
      })}
      style={_.when(isLoading, { visibility: 'hidden' })}>
      {!!toggle && (
        <ToggleIcon color={color} selected={[true, 'true'].includes(props['aria-pressed'])} />
      )}
      {iconLeft}
      {!!swatch && <Swatch color={swatch} />}
      <span
        css={[
          {
            position: 'relative',
            top: !link && S.rem(-0.5),
            flex: '1 1 100%',
          },
          truncate && S.textTruncate,
        ]}>
        {label}
      </span>
      {iconRight}
      {!!count && (
        <Count color={color} count={count?.content || count} float={count?.float} max={maxCount} />
      )}
    </span>
  )

  return props.href || props.search ? (
    <Link css={style} type="unstyled" ref={ref} {...props} icon={linkIcon}>
      {contentContainer}
    </Link>
  ) : (
    <button css={style} disabled={disabled} ref={ref} type="button" {...props}>
      {!!isLoading && <span css={S.absoluteCenter}>{loader}</span>}
      {contentContainer}
    </button>
  )
}

const contentContainerStyles = ({ iconLeft, iconRight, ...iconParams }) => [
  {
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  (iconLeft || iconRight) && { '& > svg': iconStyles({ iconLeft, iconRight, ...iconParams }) },
]

const iconStyles = ({ hasLabel, iconLeft, iconRight, link }) => [
  {
    flex: '0 0 auto',
    bottom: S.rem(0.5),
  },
  hasLabel &&
    _.cond(
      [
        iconLeft && iconRight,
        {
          '&:nth-of-type(1)': S.space({ ml: link ? 0 : -1, mr: 1 }),
          '&:nth-of-type(2)': S.space({ mr: link ? 0 : -1, ml: 1 }),
        },
      ],
      [iconLeft, S.space({ ml: link ? 0 : -1, mr: 1 })],
      [iconRight, S.space({ mr: link ? 0 : -1, ml: 1 })]
    ),
]

const resetStyles = {
  fontWeight: 400,
  background: ' transparent',
  border: 'none',
  userSelect: 'none',
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
}

const buttonStyles = ({ color, compact, disabled, isLoading, truncate }) => [
  resetStyles,
  {
    position: 'relative',
    bottom: 'unset',
    display: 'inline-block',
    zIndex: 1,
    lineHeight: S.unit(3),
    textAlign: 'left',
    borderRadius: S.rem(40),
    cursor: disabled ? 'not-allowed' : 'pointer',
    maxWidth: truncate && '100%',

    '&:disabled': {
      color: S.colors.grey(11),
      '&:hover': {
        color: S.colors.grey(11),
      },
    },
    '&:hover': {
      textDecoration: 'none !important',
      color: colorStyles[color]?.color,
    },
    '&[data-focus-visible-added]': {
      outline: `2px solid ${S.colors.focus}`,
      outlineOffset: 2,
      zIndex: 2,
    },
  },
  color && colorStyles[color],
  color &&
    isLoading && {
      '&:disabled, &:hover:disabled': {
        color: colorStyles[color].color,
        backgroundColor: colorStyles[color].backgroundColor,
      },
    },
  S.space({ px: compact ? 2.5 : 3, py: compact ? 0.5 : 1 }),
]

const combinedLinkStyles = ({ link }) => [
  resetStyles,
  linkStyles({ type: link, icon: false, inline: link === 'inline', padding: false }),
  {
    textAlign: 'left',
    '&:hover': { textDecoration: 'none' },

    '&[data-focus-visible-added]': {
      outline: 'none',

      '> *:first-of-type': {
        outline: `2px solid ${S.colors.focus}`,
        borderRadius: S.borderRadius,
        paddingLeft: S.rem(3),
        paddingRight: S.rem(3),
        marginLeft: S.rem(-3),
        marginRight: S.rem(-3),
      },
    },
  },
]

const styles = ({ color, compact, disabled, isLoading, link, truncate }) =>
  link
    ? combinedLinkStyles({ link })
    : buttonStyles({ color, compact, disabled, isLoading, truncate })

export default Button

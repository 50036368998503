import DefaultButton from 'components/button'
import * as _ from 'modules/util'
import { use } from 'react'
import TooltipContext from './context'

export const Button = ({ as: Button = DefaultButton, ref: refIn, ...props }) => {
  const tooltip = use(TooltipContext)
  const ref = _.useMergeRefs([tooltip.refs.setReference, refIn])
  return (
    <Button ref={ref} compact={tooltip.compact} {...props} {...tooltip.getReferenceProps(props)} />
  )
}

import * as S from 'apps/cliniko/style'
import Highlight from 'components/highlight'
import * as _ from 'modules/util'

const Prose = ({ as: Component = 'p', children, highlight, light, ref, size, ...props }) => (
  <Component css={styles({ light, size })} {...props} ref={ref}>
    {highlight ? <Highlight search={highlight}>{children}</Highlight> : children}
  </Component>
)

export const styles = ({ light, size } = {}) => ({
  position: 'relative',
  fontSize: _.cond([size === 'big', S.rem(33)], [size === 'small', S.rem(12)], [S.rem(16)]),
  lineHeight: _.cond([size === 'big', S.unit(5)], [size === 'small', S.unit(2)], [lineHeight]),
  bottom: _.cond([size === 'small', S.rem(-4)], [size === 'big', 0], [baselineOffset]),
  color: light ? S.colors.grey(5) : 'inherit',
})

export const lineHeightPx = S.units(3)
export const lineHeight = S.unit(3)
export const baselineOffset = S.rem(2)

export default Prose
